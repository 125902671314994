import { getUserId } from '@src/utils/helpers/authentication';

import { favouriteFoodstuffActions } from '@ducks/favouriteFoodstuff';
import { errorActions } from '@ducks/error';
import types from './types';

const getPending = () => ({
  type: types.GET_PENDING
});

const getFulfilled = response => ({
  type: types.GET_FULFILLED,
  response
});

const getRejected = () => ({
  type: types.GET_REJECTED
});

function getFavourites() {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(getFulfilled(response));
    const rejected = error => {
      dispatch(getRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(getPending());

    return wsCalls().getFavouriteRecipes(fulfilled, rejected, getUserId());
  };
}

const resetFavouriteRecipeStatus = () => ({
  type: types.FAV_RECIPE_RESET_STATUS
});

const postPending = () => ({
  type: types.POST_PENDING
});

const postFulfilled = response => ({
  type: types.POST_FULFILLED,
  response
});

const postRejected = () => ({
  type: types.POST_REJECTED
});

function postFavourite(recipeId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(postFulfilled(response));
    const rejected = error => {
      dispatch(postRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postPending());

    return wsCalls().postFavouriteRecipe(
      fulfilled,
      rejected,
      getUserId(),
      recipeId
    );
  };
}

function postFavouriteAndGetFavourites(recipeId) {
  return dispatch =>
    dispatch(postFavourite(recipeId)).then(() =>
      updateFavouritesView(dispatch)
    );
}

const deletePending = () => ({
  type: types.DELETE_PENDING
});

const deleteFulfilled = response => ({
  type: types.DELETE_FULFILLED,
  response
});

const deleteRejected = () => ({
  type: types.DELETE_REJECTED
});

function deleteFavourite(recipeId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(deleteFulfilled(response));
    const rejected = error => {
      dispatch(deleteRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deletePending());

    return wsCalls().deleteFavouriteRecipe(
      fulfilled,
      rejected,
      getUserId(),
      recipeId
    );
  };
}

function deleteFavouriteAndGetFavourites(recipeId) {
  return dispatch =>
    dispatch(deleteFavourite(recipeId)).then(() =>
      updateFavouritesView(dispatch)
    );
}

function updateFavouritesView(dispatch) {
  dispatch(getFavourites());
  dispatch(favouriteFoodstuffActions.getFavourites());
}

export default {
  getFavourites,
  postFavouriteAndGetFavourites,
  deleteFavouriteAndGetFavourites,
  resetFavouriteRecipeStatus
};
