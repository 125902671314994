export default {
  FAST_LOG_PENDING: 'foodLog/FAST_LOG_PENDING',
  FAST_LOG_FULFILLED: 'foodLog/FAST_LOG_FULFILLED',
  FAST_LOG_REJECTED: 'foodLog/FAST_LOG_REJECTED',
  CREATE_LOG_PENDING: 'foodLog/CREATE_LOG_PENDING',
  CREATE_LOG_FULFILLED: 'foodLog/CREATE_LOG_FULFILLED',
  CREATE_LOG_REJECTED: 'foodLog/CREATE_LOG_REJECTED',
  UPDATE_LOG_PENDING: 'foodLog/UPDATE_LOG_PENDING',
  UPDATE_LOG_FULFILLED: 'foodLog/UPDATE_LOG_FULFILLED',
  UPDATE_LOG_REJECTED: 'foodLog/UPDATE_LOG_REJECTED',
  DELETE_FAST_LOG_PENDING: 'foodLog/DELETE_FAST_LOG_PENDING',
  DELETE_FAST_LOG_FULFILLED: 'foodLog/DELETE_FAST_LOG_FULFILLED',
  DELETE_FAST_LOG_REJECTED: 'foodLog/DELETE_FAST_LOG_REJECTED',
  DELETE_LOG_PENDING: 'foodLog/DELETE_LOG_PENDING',
  DELETE_LOG_FULFILLED: 'foodLog/DELETE_LOG_FULFILLED',
  DELETE_LOG_REJECTED: 'foodLog/DELETE_LOG_REJECTED',
  RESET_FAST_LOGGED_FOOD: 'foodLog/RESET_FAST_LOGGED_FOOD',
  CREATE_QUICK_LOG_PENDING: 'foodLog/CREATE_QUICK_LOG_PENDING',
  CREATE_QUICK_LOG_FULFILLED: 'foodLog/CREATE_QUICK_LOG_FULFILLED',
  CREATE_QUICK_LOG_REJECTED: 'foodLog/CREATE_QUICK_LOG_REJECTED',
  UPDATE_QUICK_LOG_PENDING: 'foodLog/UPDATE_QUICK_LOG_PENDING',
  UPDATE_QUICK_LOG_FULFILLED: 'foodLog/UPDATE_QUICK_LOG_FULFILLED',
  UPDATE_QUICK_LOG_REJECTED: 'foodLog/UPDATE_QUICK_LOG_REJECTED',
  DELETE_QUICK_LOG_PENDING: 'foodLog/DELETE_QUICK_LOG_PENDING',
  DELETE_QUICK_LOG_FULFILLED: 'foodLog/DELETE_QUICK_LOG_FULFILLED',
  DELETE_QUICK_LOG_REJECTED: 'foodLog/DELETE_QUICK_LOG_REJECTED',
  CREATE_ITEMS_LOG_FULFILLED: 'foodLog/CREATE_ITEMS_LOG_FULFILLED'
};
