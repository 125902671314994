export default {
  GET_PENDING: 'favouriteRecipe/GET_PENDING',
  GET_FULFILLED: 'favouriteRecipe/GET_FULFILLED',
  GET_REJECTED: 'favouriteRecipe/GET_REJECTED',
  POST_PENDING: 'favouriteRecipe/POST_PENDING',
  POST_FULFILLED: 'favouriteRecipe/POST_FULFILLED',
  POST_REJECTED: 'favouriteRecipe/POST_REJECTED',
  DELETE_PENDING: 'favouriteRecipe/DELETE_PENDING',
  DELETE_FULFILLED: 'favouriteRecipe/DELETE_FULFILLED',
  DELETE_REJECTED: 'favouriteRecipe/DELETE_REJECTED',
  FAV_RECIPE_RESET_STATUS: 'favouriteRecipe/FAV_RECIPE_RESET_STATUS'
};
