import get from 'lodash/get';
import { recipeSearchSelectors } from '@ducks/recipeSearch';

const idLens = item => get(item, ['id']);
const nameLens = item => get(item, ['name']);
const kcalPerPortionLens = item => get(item, ['kcalPerPortion']);
const imageLens = item => get(item, ['hasImage']);
const imageVariantsLens = item => get(item, ['images', '0', 'variants']);
const ratingAverageLens = item => get(item, ['ratingAverage']);

const getFavourites = items =>
  items.map(item => ({
    id: idLens(item),
    name: nameLens(item),
    amount: 1,
    unitName: 'port',
    kcal: kcalPerPortionLens(item),
    hasImage: !!imageLens(item),
    images: imageVariantsLens(item),
    ratingAverage: ratingAverageLens(item),
    isRecipe: true,
    tags: recipeSearchSelectors.getTags(item)
  }));

export default {
  getFavourites
};
