import { membercaseSectionUrl } from '@src/helpers/navigation';
import {
  f1,
  f2,
  f3,
  googlePlayBadge,
  appStoreBadge,
  s1Small,
  s1Large,
  s2Small,
  s2Large,
  s3Small,
  s3Large,
  s4Small,
  s4Large,
  s5Small,
  s5Large,
  contentSection
} from './assets/wellobe';

export default {
  mainFeatures: [
    {
      title:
        'Följ ditt personliga kostschema eller välj bland tusentals recept',
      description:
        'Vi gör planeringen åt dig! Du får ett personligt kostschema baserat på din kalorinivå och ditt val av proteinkällor – med receptförslag för veckans alla måltider. Du kan också välja fritt bland tusentals näringsberäknade recept.',
      mainImage: {
        alt: 'Följ ditt personliga kostschema eller välj bland tusentals recept',
        src: {
          small: f1,
          large: f1
        }
      }
    },
    {
      title: 'Ett personligt program där loggboken håller koll på kalorierna',
      description:
        'Sätt upp dina mål och få ett individanpassat program med en daglig kalorinivå. Lägg in mat och motion i loggboken och följ dina framsteg vecka för vecka.',
      mainImage: {
        alt: 'Ett personligt program där loggboken håller koll på kalorierna',
        src: {
          small: f2,
          large: f2
        }
      }
    },
    {
      title: 'Effektiva träningsprogram skapade tillsammans med våra experter',
      description:
        'Träna var och när du vill! Vi har träningsprogram för alla nivåer – oavsett om du vill promenera dig i form, förbättra din rörlighet eller testa cirkelträning. Eller varför inte anta vår populära 90-dagarsutmaning?',
      mainImage: {
        alt: 'Effektiva träningsprogram skapade tillsammans med våra experter',
        src: {
          small: f3,
          large: f3
        }
      }
    }
  ],
  aboutInfo: {
    title: 'Ett samarbete med Karolinska Institutet',
    ingress: '',
    body: 'Wellobe utvecklades 2003 i samarbete med Karolinska Institutet. Sedan starten har vi hjälpt över 500 000 medlemmar att gå ner i vikt och skaffa en hälsosammare livsstil.',
    sendOff: ''
  },
  bmiInfoLink: '/inspiration/motivation/Vb0lAJ/vad-ar-bmi-body-mass-index',
  startPageSection: {
    title: 'Wellobe för dig',
    description: 'Som medlem i Wellobe får du tillgång till:',
    list: [
      'Personligt kostschema',
      'Över 3000 näringsberäknade recept',
      'Träningsprogram för alla nivåer',
      'Frågestunder med våra experter',
      'Gemenskap i vårt Community'
    ],
    mainImage: {
      alt: 'Mobiltelefon som visar loggboken',
      src: {
        small: contentSection,
        large: contentSection
      }
    }
  },
  teasers: {
    membercase: {
      title: 'Så har vi förbättrat vår hälsa',
      description:
        'Mer energi, bättre sömn och mindre värk är bara några av de hälsofördelar som våra medlemmar har upplevt. Inspireras av våra fantastiska medlemmar som har gjort sin hälsoresa med oss på Wellobe.',
      buttonLabel: 'Läs fler framgångshistorier',
      sectionId: '58418629-852d-4650-bbd6-368169e8041e',
      url: membercaseSectionUrl
    }
  },
  quotes: {
    mainTitle: 'Vad våra medlemmar säger',
    items: [
      {
        title: 'Jag har en helt annan energi, livslust och glädje',
        description:
          'När jag säger till folk att det faktiskt var väldigt enkelt, att jag varken kände hunger eller sug under resans gång, är det knappt att de tror mig. Jag har en helt annan energi, livslust och glädje. Människor i min närhet säger att det lyser om i ögonen på mig.',
        author: 'Daniel Linder, 43'
      },
      {
        title: 'Jag kände ett lugn över att slippa tänka själv',
        description:
          'Jag fann snabbt ett lugn över att slippa tänka och räkna på vad jag stoppade i mig, för det skötte appen åt mig. Min inställning har varit att jag kan äta precis vad jag vill. Inget har varit förbjudet, men att ha en begränsad kalorinivå att röra sig med har gjort att det är lättare att välja bättre alternativ.',
        author: 'Petra Söreskog, 49'
      },
      {
        title: 'Wellobe är min livförsäkring',
        description:
          'Jag upplevde det aldrig som jobbigt. Jag följde bara appen, utan den hade det inte gått. Det var en person i Wellobes community som kallade programmet för sin livförsäkring och så har jag också valt att se på det. Jag har fått ett helt nytt liv – och detta är min livförsäkring.',
        author: 'Gerd, 63'
      }
    ]
  },
  footerAd:
    'Wellobe utvecklades 2003 i samarbete med Karolinska Institutet. Sedan starten har vi hjälpt över 500 000 medlemmar att gå ner i vikt och skaffa en hälsosammare livsstil.',
  additionalInfo: [
    {
      iconComponentName: 'HeartCheckIcon',
      text: 'Wellobe täcks av',
      textEmphasized: 'friskvårdsbidraget'
    },
    {
      iconComponentName: 'ShieldCheckIcon',
      text: 'Utvecklades med',
      textEmphasized: 'Karolinska Institutet'
    }
  ],
  howItWorks: {
    title: 'Så funkar det',
    description:
      'Du får ett personligt program skapat utifrån dina förutsättningar och mål.',
    sections: [
      {
        title: 'God och hälsosam mat',
        description:
          'Med Wellobe äter du vanlig, fräsch och nyttig mat. Du kan välja bland tusentals kalori- och näringsberäknade recept eller att följa ditt personliga kostschema – byggt utifrån din personliga plan och preferenser.',
        mainImage: {
          src: {
            small: s1Small,
            large: s1Large
          },
          alt: 'God och hälsosam mat'
        },
        list: [
          'Personligt kostschema',
          'Tusentals näringsberäknade recept',
          'Lägg in egna favoritrecept'
        ]
      },
      {
        title: 'Loggboken håller koll på kalorierna',
        description:
          'Genom att logga mat och dryck håller du dig lättare inom din dagliga kalorinivå – samtidigt som du lär dig vad maten innehåller. Appen är smart och kommer att rekommendera din senaste, vanligaste och mest omtyckta mat för att förenkla loggningen ytterligare.',
        mainImage: {
          src: {
            small: s2Small,
            large: s2Large
          },
          alt: 'Loggboken håller koll på kalorierna'
        },
        list: ['Enkel loggning av mat', 'Scanna matvaror', 'Spara favoriter']
      },
      {
        title: 'Motion och rörelse',
        description:
          'I kombination med kosten är även motion och rörelse en viktig del för din nya hälsosamma livsstil. Gillar du styrketräning, promenader eller löpning? Vi har över 20 träningsprogram för alla smaker. Du kan också lägga till egna träningspass och följa dina dagliga steg.',
        mainImage: {
          src: {
            small: s3Small,
            large: s3Large
          },
          alt: 'Motion och rörelse'
        },
        list: [
          'Unika träningsprogram',
          'Synka steg och träning med din hälsoapp',
          'Träningsupplägg för 90 dagar'
        ]
      },
      {
        title: 'Följ din hälsoresa',
        description:
          'Sätt upp dina mål och få din personliga plan. Följ sedan ditt program och se dina framsteg vecka för vecka i dina grafer. Vi hjälper dig att sätta upp delmål som gör resan enklare.',
        mainImage: {
          src: {
            small: s4Small,
            large: s4Large
          },
          alt: 'Följ din hälsoresa'
        },
        list: [
          'Följ dina framsteg',
          'Små delmål på vägen',
          'Fira dina framgångar'
        ]
      },
      {
        title: 'Gemenskap i Community',
        description:
          'En livsstilsförändring kan vara utmanande ibland. I vårt Community kan du både ge och få stöd, bolla ideér med andra medlemmar och ställa frågor om kost och träning till våra experter. ',
        mainImage: {
          src: {
            small: s5Small,
            large: s5Large
          },
          alt: 'Gemenskap i Community'
        },
        list: [
          'Få svar av våra experter',
          'Pepp och stöd i vårt medlemsforum',
          'Inspireras av andra som lyckats '
        ]
      }
    ]
  },
  bmiPageTitle: 'BMI-kalkulator',
  appStoreBadge,
  googlePlayBadge
};
