import types from './types';
import initialState from './initialState';

const exerciseLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_FASTLOG_PENDING: {
      return {
        ...state,
        fastLogPendingExerciseItemIds: [
          ...state.fastLogPendingExerciseItemIds,
          action.userExerciseActivityId
        ]
      };
    }
    case types.CREATE_FASTLOG_FULFILLED: {
      return {
        ...state,
        fastLogPendingExerciseItemIds:
          state.fastLogPendingExerciseItemIds.filter(
            item => item !== action.userExerciseActivityId
          ),
        fastLoggedExerciseItems: [
          ...state.fastLoggedExerciseItems,
          {
            activityId: action.activityId,
            userExerciseActivityId: action.response
          }
        ]
      };
    }
    case types.RESET_FAST_LOGGED_EXERCISE: {
      return { ...state, fastLoggedExerciseItems: [] };
    }
    case types.CREATE_FASTLOG_REJECTED: {
      return {
        ...state,
        fastLogPendingExerciseItemIds:
          state.fastLogPendingExerciseItemIds.filter(
            item => item !== action.userExerciseActivityId
          )
      };
    }
    case types.DELETE_FASTLOG_PENDING: {
      return {
        ...state,
        fastLogDeletePendingExerciseItemIds: [
          ...state.fastLogDeletePendingExerciseItemIds,
          action.userExerciseActivityIdHistory
        ]
      };
    }
    case types.DELETE_FASTLOG_FULFILLED: {
      return {
        ...state,
        fastLoggedExerciseItems: state.fastLoggedExerciseItems.filter(
          item => item.userExerciseActivityId !== action.userExerciseActivityId
        ),
        fastLogDeletePendingExerciseItemIds:
          state.fastLogDeletePendingExerciseItemIds.filter(
            item => item !== action.userExerciseActivityIdHistory
          )
      };
    }
    case types.DELETE_FASTLOG_REJECTED: {
      return {
        ...state,
        fastLogDeletePendingExerciseItemIds:
          state.fastLogDeletePendingExerciseItemIds.filter(
            item => item !== action.userExerciseActivityIdHistory
          )
      };
    }
    case types.CREATE_LOG_PENDING: {
      return {
        ...state,
        createLogPending: true
      };
    }
    case types.CREATE_LOG_FULFILLED: {
      return {
        ...state,
        createLogPending: false,
        createLogFulfilled: action.response
      };
    }
    case types.CREATE_LOG_REJECTED: {
      return {
        ...state,
        createLogPending: false,
        createLogRejected: action.response
      };
    }
    case types.UPDATE_LOG_PENDING: {
      return {
        ...state,
        updateLogPending: true
      };
    }
    case types.UPDATE_LOG_FULFILLED: {
      return {
        ...state,
        updateLogPending: false
      };
    }
    case types.UPDATE_LOG_REJECTED: {
      return {
        ...state,
        updateLogPending: false
      };
    }
    case types.DELETE_LOG_PENDING: {
      return {
        ...state,
        deleteLogPending: true
      };
    }
    case types.DELETE_LOG_FULFILLED: {
      return {
        ...state,
        deleteLogPending: false
      };
    }
    case types.DELETE_LOG_REJECTED: {
      return {
        ...state,
        deleteLogPending: false
      };
    }
    case types.CREATE_QUICK_LOG_PENDING: {
      return {
        ...state,
        createQuickLogPending: true
      };
    }
    case types.CREATE_QUICK_LOG_FULFILLED: {
      return {
        ...state,
        createQuickLogPending: false
      };
    }
    case types.CREATE_QUICK_LOG_REJECTED: {
      return {
        ...state,
        createQuickLogPending: false
      };
    }
    case types.UPDATE_QUICK_LOG_PENDING: {
      return {
        ...state,
        updateQuickLogPending: true
      };
    }
    case types.UPDATE_QUICK_LOG_FULFILLED: {
      return {
        ...state,
        updateQuickLogPending: false
      };
    }
    case types.UPDATE_QUICK_LOG_REJECTED: {
      return {
        ...state,
        updateQuickLogPending: false
      };
    }
    case types.DELETE_QUICK_LOG_PENDING: {
      return {
        ...state,
        deleteQuickLogPending: true
      };
    }
    case types.DELETE_QUICK_LOG_FULFILLED: {
      return {
        ...state,
        deleteQuickLogPending: false
      };
    }
    case types.DELETE_QUICK_LOG_REJECTED: {
      return {
        ...state,
        deleteQuickLogPending: false
      };
    }
    default:
      return state;
  }
};

export default exerciseLogReducer;
