import types from './types';
import initialState from './initialState';

const experimentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_EXPERIMENT_FULFILLED: {
      const items = state.items.filter(
        e => e.identifier !== action.response.identifier
      );
      return {
        ...state,
        items: [...items, action.response],
        pending: false
      };
    }
    case types.CREATE_EXPERIMENT_PENDING: {
      return {
        ...state,
        pending: true
      };
    }
    case types.CREATE_EXPERIMENT_REJECTED: {
      const items = state.items.filter(
        e => e.identifier !== action.response.identifier
      );
      return {
        ...state,
        items: [...items, action.response],
        pending: false
      };
    }
    default:
      return state;
  }
};

export default experimentReducer;
