export default {
  GET_PENDING: 'favouriteFoodstuff/GET_PENDING',
  GET_FULFILLED: 'favouriteFoodstuff/GET_FULFILLED',
  GET_REJECTED: 'favouriteFoodstuff/GET_REJECTED',
  POST_PENDING: 'favouriteFoodstuff/POST_PENDING',
  POST_FULFILLED: 'favouriteFoodstuff/POST_FULFILLED',
  POST_REJECTED: 'favouriteFoodstuff/POST_REJECTED',
  DELETE_PENDING: 'favouriteFoodstuff/DELETE_PENDING',
  DELETE_FULFILLED: 'favouriteFoodstuff/DELETE_FULFILLED',
  DELETE_REJECTED: 'favouriteFoodstuff/DELETE_REJECTED'
};
