import requestStatus from '@src/utils/helpers/requestStatus';
import { modalsTypes } from '@ducks/modals';
import { recipeSearchTypes } from '@ducks/recipeSearch';
import modals from '@src/utils/constants/modals';
import types from './types';
import initialState from './initialState';

const favouritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FAV_RECIPE_RESET_STATUS: {
      return { ...state, isPostFulfilled: false, isDeleteFulfilled: false };
    }
    case types.GET_PENDING: {
      return { ...state, getStatus: requestStatus.pending };
    }
    case types.GET_FULFILLED: {
      return {
        ...state,
        getStatus: requestStatus.fulfilled,
        favourites: action.response
      };
    }
    case types.GET_REJECTED: {
      return { ...state, getStatus: requestStatus.rejected };
    }
    case types.POST_PENDING: {
      return {
        ...state,
        isPostPending: true,
        isPostFulfilled: false,
        isDeleteFulfilled: false
      };
    }
    case types.POST_FULFILLED: {
      return {
        ...state,
        isPostPending: false,
        isPostFulfilled: true
      };
    }
    case types.POST_REJECTED: {
      return { ...state, isPostPending: false, isPostFulfilled: false };
    }
    case types.DELETE_PENDING: {
      return {
        ...state,
        isDeletePending: true,
        isDeleteFulfilled: false,
        isPostFulfilled: false
      };
    }
    case types.DELETE_FULFILLED: {
      return {
        ...state,
        isDeletePending: false,
        isDeleteFulfilled: true
      };
    }
    case types.DELETE_REJECTED: {
      return { ...state, isDeletePending: false, isDeleteFulfilled: false };
    }
    case recipeSearchTypes.RECIPE_SEARCH_FULFILLED: {
      return { ...state, isPostFulfilled: false, isDeleteFulfilled: false };
    }
    case modalsTypes.HIDE_MODAL: {
      if (action.id === modals.LOG_RECIPE_MODAL_ID) {
        return { ...state, isPostFulfilled: false, isDeleteFulfilled: false };
      }
      return state;
    }
    default:
      return state;
  }
};

export default favouritesReducer;
