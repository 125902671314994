import types from './types';
import initialState from './initialState';
import selectors from './selectors';

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ERROR: {
      return {
        ...state,
        errors: [...state.errors, selectors.getErrorMessage(action.error)]
      };
    }
    case types.REMOVE_ERROR: {
      return {
        ...state,
        errors: state.errors.filter(error => error.id !== action.id)
      };
    }
    case types.PROGRAM_REQUIRED: {
      return {
        ...state,
        isProgramRequired: action.value
      };
    }
    default:
      return state;
  }
};

export default errorReducer;
