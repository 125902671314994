import { START_SEARCH_THRESHOLD } from '@src/utils/constants/search';

const isAboveThreshold = exerciseSearchInputValue =>
  exerciseSearchInputValue
    ? exerciseSearchInputValue.length > START_SEARCH_THRESHOLD - 1
    : false;

const exerciseDefault = (strength, kcalBurnedIn30Minutes) =>
  strength
    ? { reps: 1, sets: 1 }
    : { kcal: kcalBurnedIn30Minutes, durationMinutes: 30 };

const result = ({ name, strength, kcalBurnedIn30Minutes, id }) => ({
  name,
  strength,
  id,
  ...exerciseDefault(strength, kcalBurnedIn30Minutes)
});

const searchResult = ({ exerciseSearchItems }) =>
  exerciseSearchItems.map(result);

const isSearchEmpty = ({
  exerciseSearchItems,
  exerciseSearchInputValue,
  exerciseSearchPending
}) =>
  exerciseSearchItems &&
  exerciseSearchItems.length === 0 &&
  isAboveThreshold(exerciseSearchInputValue) &&
  !exerciseSearchPending;

const isSearchValueAboveThreshold = ({ exerciseSearchInputValue }) =>
  isAboveThreshold(exerciseSearchInputValue);

export default {
  searchResult,
  isSearchEmpty,
  isSearchValueAboveThreshold
};
