export default {
  CREATE_FASTLOG_PENDING: 'exerciseLog/CREATE_FASTLOG_PENDING',
  CREATE_FASTLOG_FULFILLED: 'exerciseLog/CREATE_FASTLOG_FULFILLED',
  CREATE_FASTLOG_REJECTED: 'exerciseLog/CREATE_FASTLOG_REJECTED',
  CREATE_LOG_PENDING: 'exerciseLog/CREATE_LOG_PENDING',
  CREATE_LOG_FULFILLED: 'exerciseLog/CREATE_LOG_FULFILLED',
  CREATE_LOG_REJECTED: 'exerciseLog/CREATE_LOG_REJECTED',
  UPDATE_LOG_PENDING: 'exerciseLog/UPDATE_LOG_PENDING',
  UPDATE_LOG_FULFILLED: 'exerciseLog/UPDATE_LOG_FULFILLED',
  UPDATE_LOG_REJECTED: 'exerciseLog/UPDATE_LOG_REJECTED',
  DELETE_FASTLOG_PENDING: 'exerciseLog/DELETE_FASTLOG_PENDING',
  DELETE_FASTLOG_FULFILLED: 'exerciseLog/DELETE_FASTLOG_FULFILLED',
  DELETE_FASTLOG_REJECTED: 'exerciseLog/DELETE_FASTLOG_REJECTED',
  DELETE_LOG_PENDING: 'exerciseLog/DELETE_LOG_PENDING',
  DELETE_LOG_FULFILLED: 'exerciseLog/DELETE_LOG_FULFILLED',
  DELETE_LOG_REJECTED: 'exerciseLog/DELETE_LOG_REJECTED',
  RESET_FAST_LOGGED_EXERCISE: 'exerciseLog/RESET_FAST_LOGGED_EXERCISE',
  CREATE_QUICK_LOG_PENDING: 'exerciseLog/CREATE_QUICK_LOG_PENDING',
  CREATE_QUICK_LOG_FULFILLED: 'exerciseLog/CREATE_QUICK_LOG_FULFILLED',
  CREATE_QUICK_LOG_REJECTED: 'exerciseLog/CREATE_QUICK_LOG_REJECTED',
  UPDATE_QUICK_LOG_PENDING: 'exerciseLog/UPDATE_QUICK_LOG_PENDING',
  UPDATE_QUICK_LOG_FULFILLED: 'exerciseLog/UPDATE_QUICK_LOG_FULFILLED',
  UPDATE_QUICK_LOG_REJECTED: 'exerciseLog/UPDATE_QUICK_LOG_REJECTED',
  DELETE_QUICK_LOG_PENDING: 'exerciseLog/DELETE_QUICK_LOG_PENDING',
  DELETE_QUICK_LOG_FULFILLED: 'exerciseLog/DELETE_QUICK_LOG_FULFILLED',
  DELETE_QUICK_LOG_REJECTED: 'exerciseLog/DELETE_QUICK_LOG_REJECTED'
};
