import requestStatus from '@src/utils/helpers/requestStatus';

export default {
  getStatus: requestStatus.idle,
  favourites: [],
  isPostPending: false,
  isPostFulfilled: false,
  isDeletePending: false,
  isDeleteFulfilled: false
};
