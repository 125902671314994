import { getUserId } from '@src/utils/helpers/authentication';
import { getExercise } from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import types from './types';

const setInputValue = value => ({
  type: types.SET_SEARCH_INPUT_VALUE,
  value
});

const clearInputValue = () => ({
  type: types.RESET_INPUT_VALUE
});

const clearSearchResult = () => ({
  type: types.RESET_SEARCH_RESULT
});

const exerciseSearchPending = () => ({
  type: types.EXERCISE_SEARCH_PENDING
});

const exerciseSearchFulfilled = (response, searchString) => ({
  type: types.EXERCISE_SEARCH_FULFILLED,
  response,
  searchString
});

const exerciseSearchRejected = () => ({
  type: types.EXERCISE_SEARCH_REJECTED
});

function getSearchResult({ searchString }) {
  return dispatch => {
    const fulfilled = response =>
      dispatch(exerciseSearchFulfilled(response, searchString));
    const rejected = error => {
      dispatch(exerciseSearchRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(exerciseSearchPending());

    return getExercise(fulfilled, rejected, getUserId(), searchString);
  };
}

export default {
  getSearchResult,
  clearSearchResult,
  setInputValue,
  clearInputValue
};
