import types from './types';
import initialState from './initialState';

const updatedFastLogEventsLog = (action, previousLog) => {
  const event = {
    type: action.type,
    timestamp: Date.now(),
    ...action.eventData
  };

  if (event.group) {
    const previousEventFromSameGroup = previousLog.find(
      log => log.group === event.group
    );
    if (!event.sourceFoodItemId) {
      event.sourceFoodItemId = previousEventFromSameGroup?.sourceFoodItemId;
    }
    if (!event.foodItemId) {
      event.foodItemId = previousEventFromSameGroup?.foodItemId;
    }
  }

  if (!event.sourceFoodItemId) {
    const sourceFoodItemId = previousLog.find(
      log => log.foodItemId === event.foodItemId
    )?.sourceFoodItemId;
    if (sourceFoodItemId) event.sourceFoodItemId = sourceFoodItemId;
  }

  if (!event.foodItemId) {
    const foodItemId = previousLog.find(
      log => log.sourceFoodItemId === event.sourceFoodItemId
    )?.sourceFoodItemId;
    if (foodItemId) event.sourceFoodItemId = foodItemId;
  }

  return [event, ...previousLog];
};

const foodLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FAST_LOG_PENDING: {
      return {
        ...state,
        fastLogEventsLog: updatedFastLogEventsLog(
          action,
          state.fastLogEventsLog
        )
      };
    }
    case types.FAST_LOG_FULFILLED: {
      return {
        ...state,
        fastLogEventsLog: updatedFastLogEventsLog(
          action,
          state.fastLogEventsLog
        )
      };
    }
    case types.RESET_FAST_LOGGED_FOOD: {
      return { ...state, fastLogEventsLog: [] };
    }
    case types.FAST_LOG_REJECTED: {
      return {
        ...state,
        fastLogEventsLog: updatedFastLogEventsLog(
          action,
          state.fastLogEventsLog
        )
      };
    }
    case types.DELETE_FAST_LOG_PENDING: {
      return {
        ...state,
        fastLogEventsLog: updatedFastLogEventsLog(
          action,
          state.fastLogEventsLog
        )
      };
    }
    case types.DELETE_FAST_LOG_FULFILLED: {
      return {
        ...state,
        fastLogEventsLog: updatedFastLogEventsLog(
          action,
          state.fastLogEventsLog
        )
      };
    }
    case types.DELETE_FAST_LOG_REJECTED: {
      return {
        ...state,
        fastLogEventsLog: updatedFastLogEventsLog(
          action,
          state.fastLogEventsLog
        )
      };
    }
    case types.CREATE_ITEMS_LOG_FULFILLED: {
      return {
        ...state,
        logPending: false,
        logFulfilled: action.response
      };
    }
    case types.CREATE_LOG_PENDING: {
      return {
        ...state,
        logPending: true,
        fastLogEventsLog: updatedFastLogEventsLog(
          action,
          state.fastLogEventsLog
        )
      };
    }
    case types.CREATE_LOG_FULFILLED: {
      return {
        ...state,
        logPending: false,
        logFulfilled: action.eventData.foodItemId,
        fastLogEventsLog: updatedFastLogEventsLog(
          action,
          state.fastLogEventsLog
        )
      };
    }
    case types.CREATE_LOG_REJECTED: {
      return {
        ...state,
        logPending: false,
        logRejected: action.eventData.response,
        fastLogEventsLog: updatedFastLogEventsLog(
          action,
          state.fastLogEventsLog
        )
      };
    }
    case types.DELETE_LOG_PENDING: {
      return {
        ...state,
        deleteLogPending: true
      };
    }
    case types.DELETE_LOG_FULFILLED: {
      return {
        ...state,
        deleteLogPending: false
      };
    }
    case types.DELETE_LOG_REJECTED: {
      return {
        ...state,
        deleteLogPending: false
      };
    }
    case types.UPDATE_LOG_PENDING: {
      return {
        ...state,
        logUpdatePending: true
      };
    }
    case types.UPDATE_LOG_FULFILLED: {
      return {
        ...state,
        logUpdatePending: false
      };
    }
    case types.UPDATE_LOG_REJECTED: {
      return {
        ...state,
        logUpdatePending: false
      };
    }
    case types.CREATE_QUICK_LOG_PENDING: {
      return {
        ...state,
        createQuickLogPending: true
      };
    }
    case types.CREATE_QUICK_LOG_FULFILLED: {
      return {
        ...state,
        createQuickLogPending: false
      };
    }
    case types.CREATE_QUICK_LOG_REJECTED: {
      return {
        ...state,
        createQuickLogPending: false
      };
    }
    case types.UPDATE_QUICK_LOG_PENDING: {
      return {
        ...state,
        updateQuickLogPending: true
      };
    }
    case types.UPDATE_QUICK_LOG_FULFILLED: {
      return {
        ...state,
        updateQuickLogPending: false
      };
    }
    case types.UPDATE_QUICK_LOG_REJECTED: {
      return {
        ...state,
        updateQuickLogPending: false
      };
    }
    case types.DELETE_QUICK_LOG_PENDING: {
      return {
        ...state,
        deleteQuickLogPending: true
      };
    }
    case types.DELETE_QUICK_LOG_FULFILLED: {
      return {
        ...state,
        deleteQuickLogPending: false
      };
    }
    case types.DELETE_QUICK_LOG_REJECTED: {
      return {
        ...state,
        deleteQuickLogPending: false
      };
    }
    default:
      return state;
  }
};

export default foodLogReducer;
