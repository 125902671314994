import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const featuredReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PENDING: {
      return { ...state, getStatus: requestStatus.pending };
    }
    case types.GET_FULFILLED: {
      return {
        ...state,
        getStatus: requestStatus.fulfilled,
        items: action.response
      };
    }
    case types.GET_REJECTED: {
      return { ...state, getStatus: requestStatus.rejected };
    }
    default:
      return state;
  }
};

export default featuredReducer;
