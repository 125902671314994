import types from './types';
import initialState from './initialState';

const exerciseSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_INPUT_VALUE: {
      return { ...state, exerciseSearchInputValue: action.value };
    }
    case types.EXERCISE_SEARCH_PENDING: {
      return { ...state, exerciseSearchPending: true };
    }
    case types.EXERCISE_SEARCH_FULFILLED: {
      return {
        ...state,
        exerciseSearchPending: false,
        exerciseSearchItems:
          state.exerciseSearchInputValue === action.searchString
            ? action.response
            : state.exerciseSearchItems
      };
    }
    case types.EXERCISE_SEARCH_REJECTED: {
      return { ...state, exerciseSearchPending: false };
    }
    case types.RESET_SEARCH_RESULT: {
      return { ...state, exerciseSearchItems: [] };
    }
    case types.RESET_INPUT_VALUE: {
      return { ...state, exerciseSearchInputValue: '' };
    }
    default:
      return state;
  }
};

export default exerciseSearchReducer;
