import get from 'lodash/get';

const idLens = item => get(item, ['id']);
const nameLens = item => get(item, ['name']);
const kcalPer100Lens = item => get(item, ['kcalPer100g']);

const getFavourites = items =>
  items.map(item => ({
    id: idLens(item),
    name: nameLens(item),
    amount: 100,
    unitName: 'g',
    kcal: kcalPer100Lens(item),
    hasImage: false,
    isRecipe: false
  }));

export default {
  getFavourites
};
