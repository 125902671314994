import {
  successStoriesSectionUrl,
  inspirationSectionUrl
} from '@src/helpers/navigation';
import {
  googlePlayBadge,
  appStoreBadge,
  f1,
  f2,
  f3,
  s1Small,
  s1Large,
  s2Small,
  s2Large,
  s3Small,
  s3Large,
  s4Small,
  s4Large,
  s5Small,
  s5Large,
  contentSection
} from './assets/vektklubb';

export default {
  mainFeatures: [
    {
      title: 'Sunne oppskrifter og ukentlige menyforslag',
      description:
        'Bli inspirert til et sunnere kosthold ved hjelp av våre kaloriberegnede oppskrifter og ukentlige menyforslag. Menyforslaget er utarbeidet av klinisk ernæringsfysiologer og er tilpasset ditt kalori- og næringsbehov.',
      mainImage: {
        alt: 'Sunne oppskrifter og ukentlige menyforslag',
        src: {
          small: f1,
          large: f1
        }
      }
    },
    {
      title: 'Personlig vektprogram',
      description:
        'Gå ned inntil 1 kilo i uken og oppnå en sunn vektnedgang med varige resultater. Vi gir deg et vektprogram med anbefalt daglig kalorinivå tilpasset dine vektmål og ønsket tempo.',
      mainImage: {
        alt: 'Personlig vektprogram',
        src: {
          small: f2,
          large: f2
        }
      }
    },
    {
      title: 'Fagsterke eksperter',
      description:
        'Vektklubb har et eget ekspertpanel som består av ernæringsfysiologer, personlig trener og vanecoach. Ekspertene gir deg engasjerende artikler og holder webinarer om livsstilsendring, kosthold og aktivitet.',
      mainImage: {
        alt: 'Fagsterke eksperter',
        src: {
          small: f3,
          large: f3
        }
      }
    }
  ],
  aboutInfo: {
    title: 'Om Vektklubb',
    ingress:
      'Vektklubb har siden 2005 hjulpet tusenvis av nordmenn ned i vekt.',
    body: 'Vårt opplegg baserer seg på Helsedirektoratets nasjonale anbefalinger for kosthold og fysisk aktivitet, og er utviklet i samarbeid med Nordens fremste fedmeeksperter. Kort fortalt handler det om å spise sunn mat og mosjonere, og å få den informasjonen og de rådene du trenger for å lykkes.\nVektklubb er ikke en midlertidig slankekur eller diett, men et verktøy som skal hjelpe deg til å danne nye og sunne vaner som varer.\nVi oppfordrer deg til å spise helt vanlig og sunn mat som du liker. Da er sjansen større for å lykkes med varige livsstilsendringer.',
    sendOff: 'Bli med, du også!'
  },
  startPageSection: {
    title: 'Vektklubb for deg',
    description: 'Som medlem i Vektklubb får du tilgang til:',
    list: [
      'Kaloridagbok',
      'Skreddersydd vektprogram',
      'Kaloriberegnede oppskrifter',
      'Næringsrike menyforslag',
      'Forum for medlemmer'
    ],
    mainImage: {
      alt: 'Mobiltelefon som visar loggboken',
      src: {
        small: contentSection,
        large: contentSection
      }
    }
  },
  teasers: {
    membercase: {
      title: 'Inspirerende medlemshistorier',
      description: 'Bli inspirert av andre som har lykkes med livsstilendring.',
      buttonLabel: 'Les flere medlemshistorier',
      sectionId: 'e5401509-9871-4bfa-b916-1ae14b27a77c',
      url: successStoriesSectionUrl
    },
    frontpage: {
      title: 'Siste nytt',
      description:
        'Bli inspirert av artikler om helse, kosthold, trening, motivasjon og vekt.',
      buttonLabel: 'Les flere artikler',
      sectionId: 'frontpage',
      tagId: 'c82bfc22-3a50-49b8-9c39-67c2990ae684',
      url: inspirationSectionUrl
    }
  },
  quotes: {
    mainTitle: 'Tilbakemelding fra våre medlemmer',
    items: [
      {
        title: 'Veldig godt verktøy',
        description:
          'Et veldig godt verktøy, som er god hjelp til å motivere meg til å ta vektnedgang på alvor.',
        author: 'Vektklubb-medlem'
      },
      {
        title: 'Får meg på rett kjøl',
        description:
          'Oppmuntring, selv om jeg ikke er så flink. Det får meg på rett kjøl igjen.',
        author: 'Vektklubb-medlem'
      },
      {
        title: 'Jeg trenger Vektklubben',
        description:
          'Hjelper meg å komme meg tilbake i sporet etter en tung periode med vektoppgang. Nå er jeg kommet igang igjen og klarer der fint nå, mener jeg. Jeg trenger Vektklubben, for meg er det akkurat denne hjelpen jeg trenger.',
        author: 'Vektklubb-medlem'
      }
    ]
  },
  additionalInfo: [
    {
      iconComponentName: 'MembersStarsIcon',
      text: 'Vektklubb har siden',
      textEmphasized: '2005 hjulpet tusenvis',
      text2: 'av medlemmer ned i vekt.'
    }
  ],
  howItWorks: {
    title: 'Slik fungerer det',
    description:
      'Du får et personlig vektprogram basert på dine forutsetninger og mål.',
    sections: [
      {
        title: 'God og næringsrik mat',
        description:
          'I Vektklubb kan du spise vanlig og sunn mat - i anbefalte mengder. Vi hjelper deg å legge om til et sunnere kosthold og varige vaner med inspirerende oppskrifter og ukentlige menyforslag.',
        mainImage: {
          src: {
            small: s1Small,
            large: s1Large
          },
          alt: 'God og næringsrik mat'
        },
        list: [
          'Tusenvis av sunne oppskrifter',
          'Nye menyforslag hver uke',
          'Enkelt å finne dine favoritter'
        ]
      },
      {
        title: 'Dagboken gir deg oversikt',
        description:
          'For å gå ned i vekt er det mest effektivt å spise færre kalorier. Med vår dagbok kan du logge det du spiser og drikker, og få svar på hvor mange kalorier og hvilke næringsstoffer du får i deg. Da kan det også bli lettere å ta smarte grep som funker for deg!',
        mainImage: {
          src: {
            small: s2Small,
            large: s2Large
          },
          alt: 'Dagboken gir deg oversikt'
        },
        list: ['Enkel loggføring', 'Norske matvarer', 'Scanning av strekkoder']
      },
      {
        title: 'Beveg deg mot bedre form',
        description:
          'Selv om kostholdet er viktigst for vektnedgang, gir mosjon og bevegelse mange gode helsefordeler. I Vektklubb kan du synkronisere aktivitet og trening med Apple Health og Google Fit og få det rett inn i dagboken.',
        mainImage: {
          src: {
            small: s3Small,
            large: s3Large
          },
          alt: 'Beveg deg mot bedre form'
        },
        list: [
          'Treningstips og treningsprogram',
          'Skritteller gjennom Apple Health og Google Fit',
          'Kaloriberegning av treningsaktivitet'
        ]
      },
      {
        title: 'Følg egen utvikling',
        description:
          'Sett opp vektmål og få et personlig vektprogram som du kan justere underveis. Følg utviklingen uke for uke i grafene. Vi hjelper deg også med å sette opp delmål som vil gjøre reisen enklere.',
        mainImage: {
          src: {
            small: s4Small,
            large: s4Large
          },
          alt: 'Følg egen utvikling'
        },
        list: [
          'Følg fremgangen din',
          'Motiverende delmål',
          'Feire alle dine måloppnåelser'
        ]
      },
      {
        title: 'Fellesskap i forumet',
        description:
          'En livsstilsendring kan være utfordrende og studier viser at det er enklere å gå ned i vekt om man har sosial støtte. I vårt forum kan du både gi og få støtte, og bli kjent med andre medlemmer i samme situasjon. ',
        mainImage: {
          src: {
            small: s5Small,
            large: s5Large
          },
          alt: 'Fellesskapsfølelse i forumet'
        },
        list: [
          'Få motivasjon og støtte',
          'Bli kjent med andre medlemmer',
          'Få inspirasjon av andre som har lykkes'
        ]
      }
    ]
  },
  bmiPageTitle: 'BMI-kalkulator',
  appStoreBadge,
  googlePlayBadge,
  bmiInfoLink:
    '/inspirasjon/nyheter/P9LPOe/dette-er-bmi-og-slik-kan-du-regne-det-ut'
};
