import localize from '@src/i18n';
import { getImageUrl } from '@src/utils/helpers/images';
import { recipeSearchSelectors } from '@src/ducks/recipeSearch';

import get from 'lodash/get';

const imageVariantsLens = item => get(item, ['images', '0', 'variants']);

const constructImage = (images, hasImage, name) => {
  const SCALE_WIDTH = 600;
  const alt = name;
  let src = '';

  if (hasImage) {
    src = getImageUrl(images, SCALE_WIDTH);
  }

  return {
    alt,
    src
  };
};

const getFeatured = (recipes, baseUrl) =>
  recipes.map(recipe => ({
    id: recipe.id,
    image: constructImage(
      imageVariantsLens(recipe),
      recipe.hasImage,
      recipe.name
    ),
    title: recipe.name,
    subtitle: `${Math.round(recipe.kcal)} ${localize(
      'generic_unit_kcal'
    )} / 1 ${localize('portion_numberless')}`,
    link: `${baseUrl}/${recipe.id}`,
    tags: recipeSearchSelectors.getTags(recipe),
    isFavourite: recipe.isFavourite
  }));

const getRecipes = (recipes, recipesRoot) => getFeatured(recipes, recipesRoot);

export default {
  getFeatured,
  getRecipes
};
