import { getUserId } from '@src/utils/helpers/authentication';
import dependencies, { keys } from '@src/utils/helpers/dependencies';
import {
  healthConsentUrl,
  startProgramUrl,
  welcomeToUrl
} from '@src/helpers/navigation';

import types from './types';
import selectors from './selectors';

const logout = () => ({
  type: types.LOGOUT_USER
});

const removeError = id => ({
  type: types.REMOVE_ERROR,
  id
});

const addError = error => ({
  type: types.ADD_ERROR,
  error
});

const programRequired = value => ({
  type: types.PROGRAM_REQUIRED,
  value
});

const healthConsentRequired = () => ({
  type: types.HEALTH_CONSENT_REQUIRED
});

function errorHandler(error, isProgramStartPage = false) {
  return dispatch => {
    // Get the error response status code
    const status = selectors.statusLens(error);

    if (status === 401 || status === 403 || !getUserId()) {
      // Status 401 (Unauthorized) will occur when:
      // - API fails to authenticate the user
      // - User with active Spid session and without a Viktklubb subscription
      // visits a route, e.g. /v7/logbook.
      // The reason for a user without a Viktklubb subscription will generate
      // status 401 is because the cookie `vkUserId` will not be set unless the
      // user has a valid Viktklubb subscription.
      // Status 403 (Forbidden) will occur when:
      // - ?
      dispatch(logout());

      const schibstedAccount = dependencies.get(keys.SCHIBSTED_ACCOUNT) || {};

      schibstedAccount.authenticate(window.location.href);
    } else if (status === 449) {
      // Status 449 (Retry With) will occur when:
      // - A user has a Spid session, and a membership, but not started
      // a program.
      dispatch(programRequired(true));

      if (!isProgramStartPage && window.location.pathname !== startProgramUrl) {
        window.location.replace(startProgramUrl);
      }
    } else if (status === 448) {
      // Status 448 will occur when user is required to consent to storing health data
      dispatch(healthConsentRequired());
      const isNotHealthConsentPage =
        window.location.pathname !== healthConsentUrl;
      const isNotWelcomeToPage = window.location.pathname !== welcomeToUrl;
      if (isNotHealthConsentPage && isNotWelcomeToPage) {
        window.location.href = healthConsentUrl;
      }
    } else {
      dispatch(addError(error));
    }
  };
}

export default {
  errorHandler,
  removeError,
  addError
};
