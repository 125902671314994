export default {
  fastLogEventsLog: [],
  logPending: false,
  logFulfilled: null,
  logRejected: null,
  logInProgressFoodItems: [],
  deleteInProgressFoodItems: [],
  updateInProgressFoodItems: [],
  deleteLogPending: false,
  logUpdatePending: false,
  createQuickLogPending: false,
  updateQuickLogPending: false,
  deleteQuickLogPending: false
};
