import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const featuredPending = () => ({
  type: types.GET_PENDING
});

const featuredFulfilled = response => ({
  type: types.GET_FULFILLED,
  response
});

const featuterdRejected = () => ({
  type: types.GET_REJECTED
});

function getFeaturedRecipes({ max = 12, recipeSearchPage = 1 } = {}) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(featuredFulfilled(response));
    const rejected = error => {
      dispatch(featuterdRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(featuredPending());

    return wsCalls().getFeaturedRecipes(
      fulfilled,
      rejected,
      getUserId(),
      max * recipeSearchPage
    );
  };
}

export default {
  getFeaturedRecipes
};
