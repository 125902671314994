import { getUserId } from '@src/utils/helpers/authentication';
import { historyExerciseSelectors } from '@ducks/historyExercise';
import { errorActions } from '@ducks/error';
import { logActions } from '@ducks/log';
import { kcalActions } from '@ducks/kcal';
import { modalsActions } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import { getStartOfWeek, getEndOfWeek } from '@src/utils/helpers/date';
import types from './types';

const fastLogPending = userExerciseActivityId => ({
  type: types.CREATE_FASTLOG_PENDING,
  userExerciseActivityId
});

const fastLogFulfilled = (response, activityId, userExerciseActivityId) => ({
  type: types.CREATE_FASTLOG_FULFILLED,
  response,
  activityId,
  userExerciseActivityId
});

const fastLogRejected = userExerciseActivityId => ({
  type: types.CREATE_FASTLOG_REJECTED,
  userExerciseActivityId
});

function postFastLoggedExercise(payload, date, userExerciseActivityId) {
  return (dispatch, _, wsCalls) => {
    const activityId = historyExerciseSelectors.activityIdLens(payload);

    const fulfilled = response =>
      dispatch(fastLogFulfilled(response, activityId, userExerciseActivityId));

    const rejected = error => {
      dispatch(fastLogRejected(userExerciseActivityId));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(fastLogPending(userExerciseActivityId));

    return wsCalls().postLoggedExerciseItem(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date
    );
  };
}

function postFastLoggedExerciseAndUpdateDashboard(
  payload,
  date,
  userExerciseActivityId
) {
  return dispatch =>
    dispatch(
      postFastLoggedExercise(payload, date, userExerciseActivityId)
    ).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          isExercise: true
        })
      );
      updateDashboard(dispatch, date);
    });
}

const logPending = () => ({
  type: types.CREATE_LOG_PENDING
});

const logFulfilled = response => ({
  type: types.CREATE_LOG_FULFILLED,
  response
});

const logRejected = response => ({
  type: types.CREATE_LOG_REJECTED,
  response
});

const logFulfilledThunk = response => dispatch => {
  dispatch(logFulfilled(response));
  dispatch(modalsActions.hideModal(modals.LOG_EXERCISE_MODAL_ID));
};

function postLoggedExercise(payload, date) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(logFulfilledThunk(response));

    const rejected = error => {
      dispatch(logRejected(error));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(logPending());

    return wsCalls().postLoggedExerciseItem(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date
    );
  };
}

function postLoggedExerciseAndUpdateDashboard(payload, date) {
  return dispatch =>
    dispatch(postLoggedExercise(payload, date)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          isExercise: true
        })
      );
      updateDashboard(dispatch, date);
    });
}

const updatePending = () => ({
  type: types.UPDATE_LOG_PENDING
});

const updateFulfilled = response => ({
  type: types.UPDATE_LOG_FULFILLED,
  response
});

const updateRejected = () => ({
  type: types.UPDATE_LOG_REJECTED
});

const updateFulfilledThunk = response => dispatch => {
  dispatch(updateFulfilled(response));
  dispatch(modalsActions.hideModal(modals.LOG_EXERCISE_MODAL_ID));
};

function putLoggedExercise(payload, date, itemId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(updateFulfilledThunk(response));

    const rejected = error => {
      dispatch(updateRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(updatePending());

    return wsCalls().putLoggedExerciseItem(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date,
      itemId
    );
  };
}

function putLoggedExerciseAndUpdateDashboard(payload, date, itemId) {
  return dispatch =>
    dispatch(putLoggedExercise(payload, date, itemId)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          isExercise: true
        })
      );
      updateDashboard(dispatch, date);
    });
}

const resetFastLoggedExercise = () => ({
  type: types.RESET_FAST_LOGGED_EXERCISE
});

const deleteFastLogPending = userExerciseActivityIdHistory => ({
  type: types.DELETE_FASTLOG_PENDING,
  userExerciseActivityIdHistory
});

const deleteFastLogFulfilled = userExerciseActivityId => ({
  type: types.DELETE_FASTLOG_FULFILLED,
  userExerciseActivityId
});

const deleteFastLogRejected = userExerciseActivityIdHistory => ({
  type: types.DELETE_FASTLOG_REJECTED,
  userExerciseActivityIdHistory
});

function deleteFastLoggedExercise(date, userExerciseActivityIdLogged) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () =>
      dispatch(deleteFastLogFulfilled(userExerciseActivityIdLogged));
    const rejected = error => {
      dispatch(deleteFastLogRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deleteFastLogPending());

    return wsCalls().deleteLoggedExerciseItem(
      fulfilled,
      rejected,
      getUserId(),
      date,
      userExerciseActivityIdLogged
    );
  };
}

function deleteFastLoggedExerciseAndUpdateDashboard(
  date,
  userExerciseActivityIdLogged,
  userExerciseActivityIdHistory
) {
  return dispatch =>
    dispatch(
      deleteFastLoggedExercise(
        date,
        userExerciseActivityIdLogged,
        userExerciseActivityIdHistory
      )
    ).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          isExercise: true,
          isDeleted: true
        })
      );
      updateDashboard(dispatch, date);
    });
}

function deleteLoggedExerciseAndUpdateDashboard(
  date,
  userExerciseActivityIdLogged
) {
  return dispatch =>
    dispatch(deleteLoggedExercise(date, userExerciseActivityIdLogged)).then(
      () => {
        dispatch(
          modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
            isExercise: true,
            isDeleted: true
          })
        );
        updateDashboard(dispatch, date);
      }
    );
}

const deleteLogPending = () => ({
  type: types.DELETE_LOG_PENDING
});

const deleteLogFulfilled = () => ({
  type: types.DELETE_LOG_FULFILLED
});

const deleteLogRejected = () => ({
  type: types.DELETE_LOG_REJECTED
});

const deleteLogFulfilledThunk = () => dispatch => {
  dispatch(deleteLogFulfilled());
  dispatch(modalsActions.hideModal(modals.LOG_EXERCISE_MODAL_ID));
};

function deleteLoggedExercise(date, userExerciseActivityIdLogged) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(deleteLogFulfilledThunk());
    const rejected = error => {
      dispatch(deleteLogRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deleteLogPending());

    return wsCalls().deleteLoggedExerciseItem(
      fulfilled,
      rejected,
      getUserId(),
      date,
      userExerciseActivityIdLogged
    );
  };
}

const createQuickLogPending = () => ({
  type: types.CREATE_QUICK_LOG_PENDING
});

const createQuickLogFulfilled = () => ({
  type: types.CREATE_QUICK_LOG_FULFILLED
});

const createQuickLogRejected = () => ({
  type: types.CREATE_QUICK_LOG_REJECTED
});

const createQuickLogFulfilledThunk = () => dispatch => {
  dispatch(createQuickLogFulfilled());
  dispatch(modalsActions.hideModal(modals.QUICK_LOG_MODAL));
};

function postQuickLog(payload, date) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(createQuickLogFulfilledThunk());

    const rejected = error => {
      dispatch(createQuickLogRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(createQuickLogPending());

    return wsCalls().postQuickLogExercise(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date
    );
  };
}

function postQuickLogAndUpdateDashboard(payload, date) {
  return dispatch =>
    dispatch(postQuickLog(payload, date)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          isExercise: true
        })
      );
      updateDashboard(dispatch, date);
    });
}

const updateQuickLogPending = () => ({
  type: types.UPDATE_QUICK_LOG_PENDING
});

const updateQuickLogFulfilled = () => ({
  type: types.UPDATE_QUICK_LOG_FULFILLED
});

const updateQuickLogRejected = () => ({
  type: types.UPDATE_QUICK_LOG_REJECTED
});

const updateQuickLogFulfilledThunk = () => dispatch => {
  dispatch(updateQuickLogFulfilled());
  dispatch(modalsActions.hideModal(modals.QUICK_LOG_MODAL));
};

function putQuickLog(payload, date, itemId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(updateQuickLogFulfilledThunk());

    const rejected = error => {
      dispatch(updateQuickLogRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(updateQuickLogPending());

    return wsCalls().putQuickLogExercise(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date,
      itemId
    );
  };
}

function putQuickLogAndUpdateDashboard(payload, date, itemId) {
  return dispatch =>
    dispatch(putQuickLog(payload, date, itemId)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          isExercise: true
        })
      );
      updateDashboard(dispatch, date);
    });
}

const deleteQuickLogPending = () => ({
  type: types.DELETE_QUICK_LOG_PENDING
});

const deleteQuickLogFulfilled = () => ({
  type: types.DELETE_QUICK_LOG_FULFILLED
});

const deleteQuickLogRejected = () => ({
  type: types.DELETE_QUICK_LOG_REJECTED
});

const deleteQuickLogFulfilledThunk = () => dispatch => {
  dispatch(deleteQuickLogFulfilled());
  dispatch(modalsActions.hideModal(modals.QUICK_LOG_MODAL));
};

function deleteQuickLog(date, itemId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(deleteQuickLogFulfilledThunk());
    const rejected = error => {
      dispatch(deleteQuickLogRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deleteQuickLogPending());

    return wsCalls().deleteLoggedExerciseItem(
      fulfilled,
      rejected,
      getUserId(),
      date,
      itemId
    );
  };
}

function deleteQuickLogAndUpdateDashboard(date, itemId) {
  return dispatch =>
    dispatch(deleteQuickLog(date, itemId)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          isExercise: true,
          isDeleted: true
        })
      );
      updateDashboard(dispatch, date);
    });
}

function updateDashboard(dispatch, date) {
  dispatch(logActions.getLog(date));
  dispatch(kcalActions.getKcalnet(getStartOfWeek(date), getEndOfWeek(date)));
  dispatch(kcalActions.getKcalnetFourWeeks());
}

export default {
  postFastLoggedExerciseAndUpdateDashboard,
  postLoggedExerciseAndUpdateDashboard,
  putLoggedExerciseAndUpdateDashboard,
  deleteFastLoggedExerciseAndUpdateDashboard,
  deleteLoggedExerciseAndUpdateDashboard,
  resetFastLoggedExercise,
  postQuickLogAndUpdateDashboard,
  putQuickLogAndUpdateDashboard,
  deleteQuickLogAndUpdateDashboard
};
