import types from './types';

const changeDisplayedComponent = (page, component) => ({
  type: types.CHANGE_DISPLAYED_COMPONENT,
  page,
  component
});

const resetToDefault = () => ({
  type: types.RESET_TO_DEFAULT
});

export default {
  changeDisplayedComponent,
  resetToDefault
};
