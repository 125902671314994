import { getUserId } from '@src/utils/helpers/authentication';

import types from './types';

const experimentFulfilled = response => ({
  type: types.CREATE_EXPERIMENT_FULFILLED,
  response
});

const experimentRejected = response => ({
  type: types.CREATE_EXPERIMENT_REJECTED,
  response
});

const experimentPending = () => ({
  type: types.CREATE_EXPERIMENT_PENDING
});

function setExperiment(experiment) {
  return dispatch => {
    dispatch(experimentFulfilled({ ...experiment, active: true }));
  };
}

function getExperiment(experimentName) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(experimentFulfilled({ ...response, active: true }));
    };

    const rejected = error => {
      dispatch(
        experimentRejected({
          identifier: experimentName,
          active: false,
          error: error && error.message
        })
      );
    };

    dispatch(experimentPending());

    return wsCalls().getExperiment(
      fulfilled,
      rejected,
      getUserId(),
      experimentName
    );
  };
}

export default {
  getExperiment,
  setExperiment
};
