import { getUserId } from '@src/utils/helpers/authentication';
import { historyFoodSelectors } from '@ducks/historyFood';
import { errorActions } from '@ducks/error';
import { logActions } from '@ducks/log';
import { kcalActions } from '@ducks/kcal';
import { modalsActions } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import { getStartOfWeek, getEndOfWeek } from '@src/utils/helpers/date';
import types from './types';

const fastLogPending = eventData => ({
  type: types.FAST_LOG_PENDING,
  eventData
});

const fastLogFulfilled = eventData => ({
  type: types.FAST_LOG_FULFILLED,
  eventData
});

const fastLogRejected = eventData => ({
  type: types.FAST_LOG_REJECTED,
  eventData
});

function postFastLog(
  payload,
  date,
  mealType,
  foodItemId,
  trackFastLogFoodLogged
) {
  return (dispatch, _, wsCalls) => {
    const foodId = historyFoodSelectors.foodIdLens(payload);
    const kind = historyFoodSelectors.foodKindLens(payload);
    const eventData = {
      date,
      mealType,
      foodId,
      kind,
      sourceFoodItemId: foodItemId,
      group: new Date().valueOf()
    };
    const fulfilled = response => {
      if (trackFastLogFoodLogged) trackFastLogFoodLogged();
      return dispatch(fastLogFulfilled({ ...eventData, foodItemId: response }));
    };

    const rejected = error => {
      dispatch(fastLogRejected(eventData));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(fastLogPending(eventData));

    return wsCalls().postLoggedFoodItem(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date,
      mealType
    );
  };
}

function postFastLogFood(
  payload,
  date,
  mealType,
  foodItemId,
  trackFastLogFoodLogged
) {
  return async dispatch => {
    await dispatch(
      postFastLog(payload, date, mealType, foodItemId, trackFastLogFoodLogged)
    );
    dispatch(
      modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
        mealType,
        date
      })
    );
  };
}

function postFastLogFoodAndUpdateDashboard(
  payload,
  date,
  mealType,
  foodItemId,
  track
) {
  return async dispatch => {
    await dispatch(postFastLogFood(payload, date, mealType, foodItemId, track));
    updateDashboard(dispatch, date);
  };
}

const logPending = eventData => ({
  type: types.CREATE_LOG_PENDING,
  eventData
});

const logFulfilled = eventData => ({
  type: types.CREATE_LOG_FULFILLED,
  eventData
});

const logRejected = eventData => ({
  type: types.CREATE_LOG_REJECTED,
  eventData
});

const logFulfilledThunk = (eventData, navigateBackToMenu) => dispatch => {
  dispatch(logFulfilled(eventData));
  dispatch(modalsActions.hideModal(modals.LOG_FOODSTUFF_MODAL_ID));
  dispatch(modalsActions.hideModal(modals.LOG_RECIPE_MODAL_ID, true));
  if (navigateBackToMenu) navigateBackToMenu();
};

function postLogFood(payload, date, mealType, sourceFoodItemId) {
  return (dispatch, _, wsCalls) => {
    const eventData = {
      date,
      mealType,
      foodId: payload.food.id,
      sourceFoodItemId,
      group: new Date().valueOf()
    };
    const fulfilled = response =>
      dispatch(
        logFulfilledThunk(
          {
            ...eventData,
            foodItemId: response
          },
          payload.navigateBackToMenu
        )
      );

    const rejected = error => {
      dispatch(logRejected({ eventData, ...error }));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(logPending(eventData));

    return wsCalls().postLoggedFoodItem(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date,
      mealType
    );
  };
}

function postLogFoodAndUpdateDashboard(
  payload,
  date,
  mealType,
  currentDate,
  sourceFoodItemId
) {
  return dispatch =>
    dispatch(postLogFood(payload, date, mealType, sourceFoodItemId)).then(
      () => {
        dispatch(
          modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
            mealType,
            date
          })
        );
        updateDashboard(dispatch, currentDate || date);
      }
    );
}

const logUpdatePending = () => ({
  type: types.UPDATE_LOG_PENDING
});

const logUpdateFulfilled = () => ({
  type: types.UPDATE_LOG_FULFILLED
});

const logUpdateRejected = () => ({
  type: types.UPDATE_LOG_REJECTED
});

const logUpdateFulfilledThunk = () => dispatch => {
  dispatch(logUpdateFulfilled());
  dispatch(modalsActions.hideModal(modals.LOG_FOODSTUFF_MODAL_ID));
  dispatch(modalsActions.hideModal(modals.LOG_RECIPE_MODAL_ID, true));
};

function putLogFood(payload, date, mealType, foodItemId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(logUpdateFulfilledThunk());

    const rejected = error => {
      dispatch(logUpdateRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(logUpdatePending());

    return wsCalls().putLoggedFoodItem(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date,
      mealType,
      foodItemId
    );
  };
}

function putLogFoodAndUpdateDashboard(payload, date, mealType, foodItemId) {
  return dispatch =>
    dispatch(putLogFood(payload, date, mealType, foodItemId)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          mealType,
          date
        })
      );
      updateDashboard(dispatch, date);
    });
}

const resetFastLoggedFood = () => ({
  type: types.RESET_FAST_LOGGED_FOOD
});

const deleteFastLogPending = eventData => ({
  type: types.DELETE_FAST_LOG_PENDING,
  eventData
});

const deleteFastLogFulfilled = eventData => ({
  type: types.DELETE_FAST_LOG_FULFILLED,
  eventData
});

const deleteFastLogRejected = eventData => ({
  type: types.DELETE_FAST_LOG_REJECTED,
  eventData
});

function deleteFastLogged(
  date,
  mealType,
  foodItemIdLogged,
  trackDeletedFastLoggedFood
) {
  return (dispatch, _, wsCalls) => {
    const eventData = {
      date,
      mealType,
      foodItemId: foodItemIdLogged,
      group: new Date().valueOf()
    };
    const fulfilled = () => {
      trackDeletedFastLoggedFood?.();
      dispatch(deleteFastLogFulfilled(eventData));
    };

    const rejected = error => {
      dispatch(deleteFastLogRejected(eventData));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deleteFastLogPending(eventData));

    return wsCalls().deleteLoggedFoodItem(
      fulfilled,
      rejected,
      getUserId(),
      date,
      mealType,
      foodItemIdLogged
    );
  };
}

function deleteFastLoggedFood(
  date,
  mealType,
  foodItemIdLogged,
  trackDeletedFastLoggedFood
) {
  return dispatch =>
    dispatch(
      deleteFastLogged(
        date,
        mealType,
        foodItemIdLogged,
        trackDeletedFastLoggedFood
      )
    ).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          mealType,
          isDeleted: true
        })
      );
    });
}

function deleteFastLoggedFoodAndUpdateDashboard(
  date,
  mealType,
  foodItemIdLogged,
  trackDeletedFastLoggedFood
) {
  return async dispatch => {
    await dispatch(
      deleteFastLoggedFood(
        date,
        mealType,
        foodItemIdLogged,
        trackDeletedFastLoggedFood
      )
    );
    updateDashboard(dispatch, date);
  };
}

function batchDeleteFastLoggedFood(fastLoggedFood, trackingFuction) {
  return async dispatch => {
    await Promise.all(
      fastLoggedFood.map(({ date, mealType, foodItemId }) =>
        dispatch(deleteFastLogged(date, mealType, foodItemId))
      )
    );
    trackingFuction?.();
    const uniqueMealTypes = [
      ...new Set(fastLoggedFood.map(({ mealType }) => mealType))
    ];
    uniqueMealTypes.forEach(mealType => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          mealType,
          isDeleted: true
        })
      );
    });
  };
}

const deleteLogPending = eventData => ({
  type: types.DELETE_LOG_PENDING,
  eventData
});

const deleteLogFulfilled = eventData => ({
  type: types.DELETE_LOG_FULFILLED,
  eventData
});

const deleteLogRejected = eventData => ({
  type: types.DELETE_LOG_REJECTED,
  eventData
});

const deleteLogFulfilledThunk = eventData => dispatch => {
  dispatch(deleteLogFulfilled(eventData));
  dispatch(modalsActions.hideModal(modals.LOG_FOODSTUFF_MODAL_ID));
  dispatch(modalsActions.hideModal(modals.LOG_RECIPE_MODAL_ID, true));
};

function deleteLoggedFood(date, mealType, foodItemIdLogged) {
  return (dispatch, _, wsCalls) => {
    const eventData = {
      date,
      mealType,
      foodItemId: foodItemIdLogged,
      group: new Date().valueOf()
    };
    const fulfilled = () => dispatch(deleteLogFulfilledThunk(eventData));
    const rejected = () => dispatch(deleteLogRejected(eventData));

    dispatch(deleteLogPending(eventData));

    return wsCalls().deleteLoggedFoodItem(
      fulfilled,
      rejected,
      getUserId(),
      date,
      mealType,
      foodItemIdLogged
    );
  };
}

function deleteLoggedFoodAndUpdateDashboard(date, mealType, foodItemIdLogged) {
  return dispatch =>
    dispatch(deleteLoggedFood(date, mealType, foodItemIdLogged)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          mealType,
          isDeleted: true
        })
      );
      updateDashboard(dispatch, date);
    });
}

const createQuickLogPending = () => ({
  type: types.CREATE_QUICK_LOG_PENDING
});

const createQuickLogFulfilled = () => ({
  type: types.CREATE_QUICK_LOG_FULFILLED
});

const createQuickLogRejected = () => ({
  type: types.CREATE_QUICK_LOG_REJECTED
});

const createQuickLogFulfilledThunk = () => dispatch => {
  dispatch(createQuickLogFulfilled());
  dispatch(modalsActions.hideModal(modals.QUICK_LOG_MODAL));
};

function postQuickLog(payload, date, mealType) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(createQuickLogFulfilledThunk());

    const rejected = error => {
      dispatch(createQuickLogRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(createQuickLogPending());

    return wsCalls().postQuickLogFood(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date,
      mealType
    );
  };
}

function postQuickLogAndUpdateDashboard(payload, date, mealType) {
  return dispatch =>
    dispatch(postQuickLog(payload, date, mealType)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          mealType
        })
      );
      updateDashboard(dispatch, date);
    });
}

const updateQuickLogPending = () => ({
  type: types.UPDATE_QUICK_LOG_PENDING
});

const updateQuickLogFulfilled = () => ({
  type: types.UPDATE_QUICK_LOG_FULFILLED
});

const updateQuickLogRejected = () => ({
  type: types.UPDATE_QUICK_LOG_REJECTED
});

const updateQuickLogFulfilledThunk = () => dispatch => {
  dispatch(updateQuickLogFulfilled());
  dispatch(modalsActions.hideModal(modals.QUICK_LOG_MODAL));
};

function putQuickLog(payload, date, mealType, foodItemId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(updateQuickLogFulfilledThunk());

    const rejected = error => {
      dispatch(updateQuickLogRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(updateQuickLogPending());

    return wsCalls().putQuickLogFood(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date,
      mealType,
      foodItemId
    );
  };
}

function putQuickLogAndUpdateDashboard(payload, date, mealType, foodItemId) {
  return dispatch =>
    dispatch(putQuickLog(payload, date, mealType, foodItemId)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          mealType
        })
      );
      updateDashboard(dispatch, date);
    });
}

const deleteQuickLogPending = () => ({
  type: types.DELETE_QUICK_LOG_PENDING
});

const deleteQuickLogFulfilled = () => ({
  type: types.DELETE_QUICK_LOG_FULFILLED
});

const deleteQuickLogRejected = () => ({
  type: types.DELETE_QUICK_LOG_REJECTED
});

const deleteQuickLogFulfilledThunk = () => dispatch => {
  dispatch(deleteQuickLogFulfilled());
  dispatch(modalsActions.hideModal(modals.QUICK_LOG_MODAL));
};

function deleteQuickLog(date, mealType, foodItemId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(deleteQuickLogFulfilledThunk());

    const rejected = error => {
      dispatch(deleteQuickLogRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deleteQuickLogPending());

    return wsCalls().deleteLoggedFoodItem(
      fulfilled,
      rejected,
      getUserId(),
      date,
      mealType,
      foodItemId
    );
  };
}

function deleteQuickLogAndUpdateDashboard(date, mealType, foodItemId) {
  return dispatch =>
    dispatch(deleteQuickLog(date, mealType, foodItemId)).then(() => {
      dispatch(
        modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
          mealType,
          isDeleted: true
        })
      );
      updateDashboard(dispatch, date);
    });
}

function updateDashboard(dispatch, date) {
  dispatch(logActions.getLog(date));
  dispatch(kcalActions.getKcalnet(getStartOfWeek(date), getEndOfWeek(date)));
  dispatch(kcalActions.getKcalnetFourWeeks());
}

function postFastLogFoodItems(foodItems, date, mealType, trackingFunction) {
  return (dispatch, _, wsCalls) => {
    const group = new Date().valueOf();
    const fulfilled = ({ ids }) => {
      foodItems.forEach(({ foodItemId, food: { id: foodId, kind } }, i) => {
        dispatch(
          fastLogFulfilled({
            foodItemId: ids[i],
            foodId,
            mealType,
            kind,
            sourceFoodItemId: foodItemId,
            date,
            group
          })
        );
      });
      trackingFunction?.();
    };

    const rejected = error => {
      dispatch(fastLogRejected(error));
      dispatch(errorActions.errorHandler(error));
    };

    foodItems.forEach(({ foodItemId, food: { id: foodId, kind } }) => {
      dispatch(
        fastLogPending({
          foodId,
          mealType,
          kind,
          sourceFoodItemId: foodItemId,
          date,
          group
        })
      );
    });

    return wsCalls().postLoggedFoodItems(
      fulfilled,
      rejected,
      { foodItems },
      getUserId(),
      date,
      mealType
    );
  };
}

function batchFastLogFood(foodItems, date, mealType, trackingFunction) {
  return async dispatch => {
    await dispatch(
      postFastLogFoodItems(foodItems, date, mealType, trackingFunction)
    );
    dispatch(
      modalsActions.showModal(modals.LOG_CONFIRMATION_MODAL, {
        mealType
      })
    );
  };
}
export default {
  postFastLogFoodAndUpdateDashboard,
  postLogFoodAndUpdateDashboard,
  deleteFastLoggedFoodAndUpdateDashboard,
  deleteLoggedFoodAndUpdateDashboard,
  putLogFoodAndUpdateDashboard,
  resetFastLoggedFood,
  postQuickLogAndUpdateDashboard,
  putQuickLogAndUpdateDashboard,
  deleteQuickLogAndUpdateDashboard,
  batchFastLogFood,
  batchDeleteFastLoggedFood,
  postFastLogFood,
  deleteFastLoggedFood
};
