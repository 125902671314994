export default {
  fastLogPendingExerciseItemIds: [],
  fastLogRejected: null,
  fastLoggedExerciseItems: [],
  fastLogDeletePendingExerciseItemIds: [],
  createLogPending: false,
  createLogFulfilled: null,
  createLogRejected: null,
  updateLogPending: false,
  deleteLogPending: false,
  createQuickLogPending: false,
  updateQuickLogPending: false,
  deleteQuickLogPending: false
};
