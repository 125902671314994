import { getUserId } from '@src/utils/helpers/authentication';
import {
  getFavouriteFoodstuff,
  postFavouriteFoodstuff,
  deleteFavouriteFoodstuff
} from '@src/utils/helpers/wsCalls';
import { favouriteRecipeActions } from '@ducks/favouriteRecipe';
import { errorActions } from '@ducks/error';
import types from './types';

const getPending = () => ({
  type: types.GET_PENDING
});

const getFulfilled = response => ({
  type: types.GET_FULFILLED,
  response
});

const getRejected = () => ({
  type: types.GET_REJECTED
});

function getFavourites() {
  return dispatch => {
    const fulfilled = response => dispatch(getFulfilled(response));
    const rejected = error => {
      dispatch(getRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(getPending());

    return getFavouriteFoodstuff(fulfilled, rejected, getUserId());
  };
}

const postPending = () => ({
  type: types.POST_PENDING
});

const postFulfilled = response => ({
  type: types.POST_FULFILLED,
  response
});

const postRejected = () => ({
  type: types.POST_REJECTED
});

function postFavourite(foodstuffId) {
  return dispatch => {
    const fulfilled = response => dispatch(postFulfilled(response));
    const rejected = error => {
      dispatch(postRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postPending());

    return postFavouriteFoodstuff(
      fulfilled,
      rejected,
      getUserId(),
      foodstuffId
    );
  };
}

function postFavouriteAndGetFavourites(foodstuffId) {
  return dispatch =>
    dispatch(postFavourite(foodstuffId)).then(() =>
      updateFavouritesView(dispatch)
    );
}

const deletePending = () => ({
  type: types.DELETE_PENDING
});

const deleteFulfilled = response => ({
  type: types.DELETE_FULFILLED,
  response
});

const deleteRejected = () => ({
  type: types.DELETE_REJECTED
});

function deleteFavourite(foodstuffId) {
  return dispatch => {
    const fulfilled = response => dispatch(deleteFulfilled(response));
    const rejected = error => {
      dispatch(deleteRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(deletePending());

    return deleteFavouriteFoodstuff(
      fulfilled,
      rejected,
      getUserId(),
      foodstuffId
    );
  };
}

function deleteFavouriteAndGetFavourites(foodstuffId) {
  return dispatch =>
    dispatch(deleteFavourite(foodstuffId)).then(() =>
      updateFavouritesView(dispatch)
    );
}

function updateFavouritesView(dispatch) {
  dispatch(getFavourites());
  dispatch(favouriteRecipeActions.getFavourites());
}

export default {
  getFavourites,
  postFavouriteAndGetFavourites,
  deleteFavouriteAndGetFavourites
};
